import Panel from '../components/uiComponents/Panel';
import Heading from '../components/uiComponents/Heading';
import JobComponent from '../components/job/JobComponent';
import JobForm from '../components/job/JobForm';
import Button from '../components/uiComponents/Button';
import {
  setShowJobAddEditModal,
  setShowJobViewModal,
  useGetAllJobsQuery,
} from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { showLoadingEffect } from '../utils/helpers';
import Modal from '../components/uiComponents/Modal';
import ViewJob from './ViewJob';
import TodoComponent from '../components/todos/TodoComponent';
import Scrollbars from 'rc-scrollbars';

function Dashboard() {
  const dispatch = useDispatch();

  const { showJobAddEditModal, showJobViewModal } = useSelector(
    (state) => state.data
  );

  const { user } = useSelector((state) => state.authentication);

  const date = new Date().toLocaleString('en-us', {
    // weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const [params, setParams] = useState({});

  const {
    data: jobsApi,
    isLoading,
    isError,
    error,
  } = useGetAllJobsQuery(params);

  useEffect(() => {
    showLoadingEffect(jobsApi, isLoading, isError, error);
  }, [dispatch, jobsApi, isLoading, isError, error]);

  useEffect(() => {
    if (user.role === 'customer') {
      setParams({ customer: user.customerId });
    }
  }, [user]);

  if (jobsApi) {
    const jobs = jobsApi;
    const completedJobs = jobs
      ?.filter((job) => job.status === 'delivered')
      .sort((a, b) => a.deadline - b.deadline);

    const pendingJobs = jobs
      ?.filter((job) => job.status !== 'delivered')
      .sort((a, b) => {
        const valueA = new Date(a.deadline);
        const valueB = new Date(b.deadline);
        return valueA - valueB;
      });

    return (
      <div className="flex flex-col gap-2 md:grid md:grid-cols-4 md:gap-4 h-full">
        <div className="col-start-1 col-end-4 row-span-2 h-full flex flex-col gap-4">
          <Panel>
            {showJobViewModal && (
              <Modal setFunction={setShowJobViewModal}>
                <ViewJob />
              </Modal>
            )}
            <div className="flex justify-between items-center">
              <Heading primary>{`${date}`}</Heading>
              <Button
                to={'/dashboard'}
                onClick={() => dispatch(setShowJobAddEditModal(true))}
              >
                add job
              </Button>
              {showJobAddEditModal && (
                <Modal setFunction={setShowJobAddEditModal}>
                  <JobForm />
                </Modal>
              )}
            </div>
          </Panel>
          <Panel className="flex flex-col gap-2 md:col-start-1 md:col-end-4 h-[800px] md:h-full">
            <Scrollbars autoHide className="rounded-md">
              <Heading secondary={true} className={' mb-2'}>
                Active Jobs
              </Heading>
              <JobComponent data={pendingJobs} />
            </Scrollbars>
          </Panel>
          <Panel className="col-start-1 col-end-4 h-full">
            <Scrollbars autoHide className="rounded-md">
              <Heading secondary={true} className={' mb-2'}>
                Completed Jobs
              </Heading>
              <JobComponent data={completedJobs} />
            </Scrollbars>
          </Panel>
        </div>

        <Panel className="relative md:col-start-4 md:col-end-5 z-50 h-[300px] md:h-full">
          <Heading primary>Todos</Heading>
          <TodoComponent />
        </Panel>
        <Panel className="col-start-4 col-end-5">
          <Heading primary>Completion</Heading>
        </Panel>
      </div>
    );
  }
}

export default Dashboard;
