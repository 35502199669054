import { useEffect, useMemo, useRef, useState } from 'react';
import Input from '../uiComponents/Input';
import Select from 'react-select';
import {
  setShowJobAddEditModal,
  useAddJobMutation,
  useGetAllCustomersQuery,
  useGetJobQuery,
  useUpdateJobMutation,
} from '../../store';

import { useParams } from 'react-router-dom';
import {
  dropdownStyles,
  dropdownTheme,
  showLoadingEffect,
  showLoadingNotificationEffect,
} from '../../utils/helpers';
import Heading from '../uiComponents/Heading';
import { useDispatch, useSelector } from 'react-redux';
import { useFieldArray, useForm } from 'react-hook-form';
import Scrollbars from 'rc-scrollbars';
import { isMobile } from 'react-device-detect';
import { HiTrash } from 'react-icons/hi2';
import Tippy from '@tippyjs/react';

function JobForm() {
  const { viewJob } = useParams();
  const { register, handleSubmit, setValue, control } = useForm();
  const { fields, append, remove } = useFieldArray({ name: 'items', control });
  const dispatch = useDispatch();

  const [isEditJob, setIsEditJob] = useState(false);
  const { user } = useSelector((state) => state.authentication);
  const scrollbarRef = useRef();

  useEffect(() => {
    if (isMobile) {
      return;
    } else {
      scrollbarRef.current?.scrollToBottom();
    }
  }, [fields]);

  const {
    data: job,
    isLoading: jobIsLoading,
    isError: jobIsError,
    error: jobError,
  } = useGetJobQuery(viewJob, {
    skip: !viewJob,
  });

  useEffect(() => {
    showLoadingEffect(job, jobIsLoading, jobIsError, jobError);
  }, [job, jobError, jobIsError, jobIsLoading]);

  const [addJob, addJobResults] = useAddJobMutation();
  const [updateJob, updateJobResults] = useUpdateJobMutation();
  const {
    data: customers,
    isLoading: customersIsLoading,
    isError: customersIsError,
    error: customerError,
  } = useGetAllCustomersQuery();

  const [selectedCustomer, setSelectedCustomer] = useState();

  useEffect(() => {
    if (viewJob) setIsEditJob(true);
    if (job) {
      // console.log(job);
      setValue('title', job.title);
      setValue('description', job.description);
      const dateApi = new Date(job.deadline);
      const dateLocal = new Date(dateApi.toLocaleString('en-us'));
      const year = dateLocal.getFullYear();
      const month = dateLocal.getMonth() + 1;
      const day = dateLocal.getDate();
      const finalDate = `${year}-${month <= 9 ? `0${month}` : month}-${
        day < 9 ? `0${day}` : day
      }`;

      setValue('deadline', finalDate);
      setSelectedCustomer({
        value: job?.customer._id,
        label: job?.customer.name,
      });
      job.items.forEach((item) =>
        append({
          name: item.name,
          orderedQuantity: item.orderedQuantity,
          status: item.status,
        })
      );
    }
  }, [append, job, setValue, viewJob]);

  // const customerOptions = [
  //   { value: 'amc', label: 'Amiri Medical Complex' },
  //   { value: 'aib', label: 'Afghanistan International Bank' },
  //   { value: 'iom', label: 'IOM' },
  // ];

  useEffect(() => {
    showLoadingNotificationEffect(addJobResults, 'Job', 'Adding');
  }, [addJobResults]);

  useEffect(() => {
    showLoadingNotificationEffect(updateJobResults, 'Job', 'Updating');
    updateJobResults.isSuccess && dispatch(setShowJobAddEditModal(false));
  }, [dispatch, updateJobResults]);

  useEffect(() => {
    showLoadingEffect(
      customers,
      customersIsLoading,
      customersIsError,
      customerError
    );
  }, [customerError, customers, customersIsError, customersIsLoading]);

  const handleDropDownChange = (option) => {
    setSelectedCustomer(option);
  };

  const handleFormSubmit = (form) => {
    form.customer = selectedCustomer.value;

    if (!isEditJob) {
      addJob(form);
    }

    if (isEditJob) {
      form.jobId = viewJob;
      updateJob(form);
    }
  };

  useEffect(() => {
    if (addJobResults.isSuccess) {
      dispatch(setShowJobAddEditModal(false));
    }
  }, [addJobResults, dispatch]);

  const customerOptions = useMemo(() => {
    if (customers) {
      return customers?.map((customer) => {
        return { value: customer._id, label: customer.name };
      });
    }
  }, [customers]);

  useEffect(() => {
    if (user.role === 'customer' && customers) {
      setSelectedCustomer(
        customerOptions?.find((customer) => customer.value === user.customerId)
      );
      console.log(customerOptions);
      console.log(
        customerOptions?.find((customer) => customer.value === user.customerId)
      );
    }
  }, [customerOptions, customers, user]);

  if (customers) {
    const renderedItemsFields = fields.map((field, i) => {
      return (
        <div key={field.id} className="flex gap-4 justify-between ">
          <div className="flex flex-col gap-2 w-3/4">
            <Input
              placeholder={'Item name'}
              required={true}
              register={register}
              label={`Item ${i + 1}`}
              id={`items.${i}.name`}
              type={'text'}
            />
          </div>
          <div className="flex flex-col gap-2 w-1/4 flex-1">
            <Input
              required={true}
              register={register}
              label={`Quantity`}
              id={`items.${i}.orderedQuantity`}
              type={'number'}
              min={1}
            />
          </div>
          <Tippy content="Delete">
            <button
              onClick={() => remove(i)}
              className="text-accent-main hover:text-red-500 transition text-lg mt-6"
            >
              <HiTrash />
            </button>
          </Tippy>
        </div>
      );
    });
    return (
      <form
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit(handleFormSubmit)}
        encType="multipart/form-data"
        className="mt-4 w-5/6 h-3/4 md:w-2/6 md:h-4/6 bg-dark-light-1 p-4 md:p-8 rounded-md "
      >
        <Scrollbars ref={scrollbarRef} className="rounded">
          <div className="flex flex-col gap-2 mx-0.5">
            <Heading primary>{isEditJob ? 'Update Job' : 'Add Job'}</Heading>
            <div className="flex flex-col gap-2">
              <label className={'font-semibold text-gray-100'}>Customer</label>
              {customers && (
                <Select
                  required
                  theme={dropdownTheme}
                  styles={dropdownStyles}
                  value={selectedCustomer}
                  options={customerOptions}
                  isDisabled={user.role === 'customer'}
                  onChange={(value) => handleDropDownChange(value)}
                />
              )}
            </div>
            <div className="flex flex-col gap-2">
              <Input
                required={true}
                placeholder="Job Title"
                label={'Title'}
                id={'title'}
                type={'text'}
                register={register}
              />
            </div>
            <div className="flex flex-col gap-2">
              <Input
                required={true}
                placeholder="Job Description"
                label={'Description'}
                register={register}
                id={'description'}
                type={'text'}
              />
            </div>
            <div className="flex flex-col gap-2">
              <Input
                required={true}
                register={register}
                label={'Deadline'}
                id={'deadline'}
                type={'date'}
              />
            </div>
            <div>
              <div>
                <Heading tertiary className="">
                  Items
                </Heading>
                {renderedItemsFields}
                {fields.length === 0 && (
                  <div className="flex align-center items-center justify-center bg-dark-light-2 text-gray-100 font-bold p-4 rounded ">
                    <p className="text-center">No Items added.</p>
                  </div>
                )}
              </div>
            </div>

            <div className="flex gap-2 mt-4 justify-end">
              <button
                type="button"
                className="self-end p-2 text-md font-bold bg-dark-light-1 border border-accent-main w-28 h-10 text-center text-accent-main rounded-md hover:bg-accent-light hover:text-dark-light-2 uppercase transition"
                onClick={() =>
                  append({
                    name: '',
                    orderedQuantity: 0,
                    status: 'designPending',
                  })
                }
              >
                Add Item
              </button>
              <button
                type="submit"
                className="self-end p-2 text-md font-bold bg-accent-main w-28 h-10 text-center text-dark-light-2 rounded-md hover:bg-accent-light uppercase transition"
              >
                {isEditJob ? 'Update' : 'Add'}
              </button>
            </div>
          </div>
        </Scrollbars>
      </form>
    );
  }
}

export default JobForm;
